import { FCH_USER_DONE, UPD_CONTENT_LANG_DONE, UPD_LANG_DONE, USER_LOGOUT, LOGIN_FAILURE, SIGNUP_FAILURE } from '../types/auth';
import { CANCEL_SUBSCRIPTION_DONE } from '../types/subscription';

const INIT_STATE = {
  user: {},
  error: '',
};

const authReducer = (_ = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case FCH_USER_DONE:
      return { ..._, user: payload };
    case UPD_LANG_DONE:
      return {
        ..._,
        user: { ..._.user, config: { ..._.user.config, lang: payload } },
      };
    case LOGIN_FAILURE:
      return { ..._, error: payload };
    case SIGNUP_FAILURE:
      return { ..._, error: payload };
    case UPD_CONTENT_LANG_DONE:
      return {
        ..._,
        user: { ..._.user, config: { ..._.user.config, content_lang: payload } },
      };
    case USER_LOGOUT:
      return { ..._, user: {} };
    case CANCEL_SUBSCRIPTION_DONE:
      return {
        ..._,
        user: { ..._.user, plan: 'free' },
      };
    default:
      return _;
  }
};

export default authReducer;
