import React from 'react';
import MediaPlayer from './MediaPlayer';
import ContentEditor from './Editor';
import { useSelector } from 'react-redux';
import Loading from '../Loading';
import MindMap from './MindMap';
import { Typography } from '@mui/material';

const NoteEditor = ({
  sourceType,
  noteView,
  userId,
  noteId,
  isImmersive,
  handleSwitchImmersive,
  handleContentDurationChange,
}) => {
  const config = useSelector(({ app }) => app.user.config);
  const note = useSelector(({ search }) => search.getIn(['results', 'note']));

  if (!note || !noteView) return <Loading />;

  if (sourceType === 'audio') {
    if (noteView === 'translate' && !note.translate) {
      return (
        <Typography variant='h4' sx={{ p: 6, pt: 3 }}>
          尚無任何內容
        </Typography>
      );
    }

    if (noteView === 'content' || noteView === 'translate') {
      return (
        <MediaPlayer
          userId={userId}
          noteId={noteId}
          content={note[noteView]}
          url={note.fileurl}
          isImmersive={isImmersive}
          handleSwitchImmersive={handleSwitchImmersive}
        />
      );
    }
    if (noteView === 'mindmap') {
      return <MindMap input={note['mindmap']} />;
    }
  }

  return (
    <ContentEditor
      note={note}
      noteId={noteId}
      userId={userId}
      noteView={noteView}
      locales={config.locales}
      handleContentDurationChange={handleContentDurationChange}
    />
  );
};

export default NoteEditor;
