import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Editor as NovelEditor } from '@liuanboy/novel';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { updNoteContent } from 'app/redux/actions/note';
import { genContent } from 'app/utils/downloadDoc';
import Div from '@jumbo/shared/Div';

const ContentEditor = ({
  value,
  noteId,
  debounceDuration = 750,
  className = 'novel-editor',
  locales,
  // noteView,
  userId,
  // note,
  // handleContentDurationChange,
}) => {
  const dispatch = useDispatch();
  const [editorKey, setEditorKey] = useState(noteId);
  const [content, setContent] = useState(null);

  const note = useSelector(({ search }) => search.getIn(['results', 'note']));
  const memoizedNote = useMemo(() => note, []);
  const noteView = useSelector(({ search }) => {
    const noteList = search.getIn(['results', 'notes']);
    const note = noteList.find(note => note.id === noteId);
    return note?.view;
  });

  const handleContentDurationChange = useCallback(
    editor => {
      const editedContent = editor.getHTML();
      const jsonContent = editor.getJSON();

      setContent(jsonContent);
      return dispatch(updNoteContent(userId, noteId, editedContent, noteView));
    },
    [dispatch, userId, noteId, noteView]
  );

  useEffect(() => {
    // When value changes, update the editorKey to force re-render
    setEditorKey(noteId + '-' + new Date().getTime());
  }, [memoizedNote[noteView], noteId]);

  useEffect(() => {
    if (note) {
      const noteContent = genContent(note, noteView);
      setContent(noteContent);
    }
  }, [memoizedNote[noteView], noteView]);

  if (noteView === 'translate' && !content) {
    return <Div sx={{ p: 6, pt: 3 }}>翻譯中...</Div>;
  }

  return (
    <NovelEditor
      key={editorKey}
      className={className}
      storageKey={noteId}
      defaultValue={content}
      // onUpdate={onUpdate}
      onDebouncedUpdate={handleContentDurationChange}
      debounceDuration={debounceDuration}
      disableLocalStorage={true}
      locales={locales}
    />
  );
};

export default React.memo(ContentEditor);
