import { generateHTML, generateJSON } from '@tiptap/core';
import Color from '@tiptap/extension-color';
import Highlight from '@tiptap/extension-highlight';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import Image from '@tiptap/extension-image';
import TLink from '@tiptap/extension-link';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import Paragraph from '@tiptap/extension-paragraph';
import Document from '@tiptap/extension-document';
import Text from '@tiptap/extension-text';
import Bold from '@tiptap/extension-bold';
import TurndownService from 'turndown';
import Italic from '@tiptap/extension-italic';
import Strike from '@tiptap/extension-strike';
import ListItem from '@tiptap/extension-list-item';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import Blockquote from '@tiptap/extension-blockquote';
import CodeBlock from '@tiptap/extension-code-block';
import HardBreak from '@tiptap/extension-hard-break';
import Heading from '@tiptap/extension-heading';
import Code from '@tiptap/extension-code';

const genListType = [
  Document,
  Color,
  Highlight,
  HorizontalRule.configure({
    HTMLAttributes: { class: 'novel-editor-hr' },
  }),
  Image.configure({ HTMLAttributes: { class: 'novel-editor-image' } }),
  TLink,
  TaskItem,
  TaskList,
  TextStyle,
  Underline,
  Paragraph.configure({ HTMLAttributes: { class: 'novel-editor-p' } }),
  Text,
  Bold,
  Italic,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  Code,
];

export const genHTML = (note, type) => {
  const contentStr = note[type];

  const htmlPattern = /<\/?[a-z][\s\S]*>/i;

  // if contentStr is html, return it
  if (htmlPattern.test(contentStr)) {
    return contentStr;
  }

  // init jsonObj
  const jsonObj = {
    type: 'doc',
    content: [],
  };

  // if contentStr is empty, add a paragraph with text "目前沒有任何內容"
  if (!contentStr) {
    jsonObj.content.push({
      type: 'paragraph',
      content: [{ text: '目前沒有任何內容', type: 'text' }],
    });

    return generateHTML(jsonObj, genListType);
  }

  // if contentStr is a valid JSON, use it
  const jsonData =
    contentStr.includes('"type":"doc"') && JSON.parse(note[type]);
  const isJSON = jsonData.hasOwnProperty('type') && jsonData.type === 'doc';

  if (!htmlPattern.test(contentStr) && !isJSON) {
    return stringToHtml(contentStr);
  }

  JSON.parse(contentStr).content.forEach(c => {
    jsonObj.content.push(c);
  });

  // if note type is image amd is not in the content, add it
  const foundItem = jsonObj.content.find(
    item =>
      item.type === 'image' && item.attrs && item.attrs.src === note.fileurl
  );

  if (note.source.type === 'image' && !foundItem) {
    const imageObj = {
      type: 'image',
      attrs: {
        src: note.fileurl,
        alt: 'image',
        title: null,
        HTMLAttributes: {
          class: 'novel-editor-image',
        },
      },
    };

    jsonObj.content.push(imageObj);
  }

  return generateHTML(jsonObj, genListType);
};

export const genContent = (note, type) => {
  const html = genHTML(note, type);

  return generateJSON(html, genListType);
};

export const genTxt = note => {
  const file = new Blob([note], {
    type: 'text/plain;charset=utf-8',
  });
  const url = URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.download = 'note.txt';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const genMarkDown = note => {
  const turndownService = new TurndownService({
    bulletListMarker: '-',
    emDelimiter: '_',
  });
  turndownService.addRule('escapeRule', {
    filter: ['p'],
    replacement: content => content.replace(/(\d+)\\\./, '$1.'),
  });

  const url = `${window.location.origin}${note.fileurl}`;
  const isHtml = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/.test(note.content);

  let html = isHtml ? note.content : note.content
    .split('\n')
    .filter(Boolean)
    .map(line => `<p>${line}</p>`)
    .join('');

  if (note.source?.type === 'image') {
    const imageUrl = `<img src="${url}" alt="Note Image" />`;
    html += imageUrl;
  }

  if (isHtml) {
    const json = JSON.parse(note.content);
    html = generateHTML(json, genListType);
  }

  return turndownService.turndown(html);
};

export const stringToHtml = str => {
  const escape = str
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
  return escape.replace(/\n/g, '<br>');
};
