import React, { useCallback } from 'react';
import {
  useNodesState,
  useEdgesState,
  addEdge,
  Background,
  Controls,
  MiniMap,
  MarkerType,
  ReactFlow,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';

function convertToNodesAndEdges(str) {
  const lines = str
    .split('\n')
    .map(line => line.trim())
    .filter(line => line);
  const nodes = [];
  const edges = [];
  let currentId = 0;
  const stack = [];

  function createNode(label, level) {
    currentId++;
    return {
      id: currentId.toString(),
      type: 'default',
      data: { label },
      position: { x: level * 200, y: currentId * 100 },
    };
  }

  lines.forEach(line => {
    const level = line.search(/\S/) / 4;
    const label = line.trim().replace(/^[-(]+|\)+$/g, '');

    if (level === 0) {
      const rootNode = createNode(label, level);
      nodes.push(rootNode);
      stack[level] = rootNode;
    } else {
      const node = createNode(label, level);
      nodes.push(node);

      if (stack[level - 1]) {
        edges.push({
          id: `e${stack[level - 1].id}-${node.id}`,
          source: stack[level - 1].id,
          target: node.id,
          type: 'smoothstep',
        });
      }

      stack[level] = node;
    }
  });

  return { nodes, edges };
}

// const input = `心智圖 處理 mindmap root((名人客串柯南)) TOMIX 高山南 柯南本人的CV 與作者青山剛昌前妻 永野追猜 音樂製作人、作詞家 草木麻衣 日本著名女歌手 為柯南獻唱24次 小松美步 為柯南獻唱4次 2006年後停止發表新作品 市川海老藏 日本國寶級歌舞伎演員 參與2020東京奧運開幕式 尚護才 日本知名女演員和歌手 錦上和鄉 日本知名女演員和模特 山本子 日本知名泳裝模特 戲政章 日本老牌主持人 上田敬 日本知名主持人 友田哲平 日本搞笑男藝人 三浦之良 日本國寶級足球員 遠藤寶人 金野太幸 尤其正剛 中村信剛 根根成絲 日本自由主持人 足利梨花 日本女演員 柯南超級粉絲 川田玉美 日本女播音員 天海佑希 日本知名女演員`;

function MindMap({ input }) {
  const { nodes: initialNodes, edges: initialEdges } =
    convertToNodesAndEdges(input);

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const onConnect = useCallback(
    params => setEdges(eds => addEdge(params, eds)),
    [setEdges]
  );

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        fitView>
        <Background />
        <Controls />
        <MiniMap />
      </ReactFlow>
    </div>
  );
}

export default MindMap;
