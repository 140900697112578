import {
  Button,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { translateNote } from 'app/redux/actions/note';

const UrlArea = ({ noteView, handleChangeNoteView, url, uId, nId, note }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenTranslate = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseTranslate = () => {
    setAnchorEl(null);
  };

  const handleTranslate = async lang => {
    setAnchorEl(null);
    setIsLoading(true);
    const res = await dispatch(translateNote(uId, nId, lang));
    if (!res.success) {
      throw new Error('Failed to translate');
    }
    handleChangeNoteView('translate');
    setIsLoading(false);
    return;
  };

  const handleGoToUrl = url => {
    window.open(url, '_blank');
  };

  const open = Boolean(anchorEl);

  return (
    <Grid item container md={12} xs={12} sx={{ p: '7px 16px' }}>
      <Grid item container md={12} xs={12} spacing={1}>
        <Grid item md={12} xs={12}>
          <Stack direction='row' spacing={1} justifyContent='center'>
            {url && (
              <Button variant='contained' onClick={() => handleGoToUrl(url)}>
                {t('NOTE_URL')}
              </Button>
            )}
            <Button
              variant='contained'
              onClick={() => handleChangeNoteView('summary')}>
              摘要
            </Button>
            <Button
              disabled={isLoading}
              startIcon={isLoading && <CircularProgress size={12} />}
              variant='contained'
              onClick={handleOpenTranslate}>
              {t('TRANSLATE')}
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseTranslate}>
              <MenuItem onClick={() => handleTranslate('en')}>英文</MenuItem>
              <MenuItem onClick={() => handleTranslate('zh')}>中文</MenuItem>
              <MenuItem onClick={() => handleTranslate('js')}>日文</MenuItem>
              <MenuItem onClick={() => handleTranslate('ko')}>韓文</MenuItem>
            </Menu>
          </Stack>
        </Grid>
        <Grid item md={2} xs={12}>
          <Select
            size='small'
            // sx={{ minWidth: { md: 375, xs: '100%' } }}
            fullWidth
            defaultValue='content'
            onChange={e => handleChangeNoteView(e.target.value)}
            value={noteView}>
            <MenuItem value='content'>筆記</MenuItem>
            <MenuItem value='summary'>摘要</MenuItem>
            {note.hasOwnProperty('translate') && (
              <MenuItem value='translate'>翻譯</MenuItem>
            )}
            {/* {sourceType === 'audio' && <MenuItem value='mindmap'>心智圖</MenuItem>} */}
          </Select>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(UrlArea);
