import React, { Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import JumboApp from '@jumbo/components/JumboApp';
import AppLayout from './AppLayout';
import JumboTheme from '@jumbo/components/JumboTheme';
import AppLayoutRoutes from './AppLayoutRoutes';
import JumboDialog from '@jumbo/components/JumboDialog';
import JumboDialogProvider from '@jumbo/components/JumboDialog/JumboDialogProvider';
import { SnackbarProvider } from 'notistack';
import AppProvider from './AppProvider';
import { config } from './config/main';
import JumboRTL from '@jumbo/JumboRTL/JumboRTL';
import Div from '@jumbo/shared/Div';
import { CircularProgress } from '@mui/material';
import { fchUser } from './redux/actions/auth';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useSearchParams } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import './utils/vConsole'; // show mobile console
import MetaHelmet from './shared/Helmet';

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const storage = localStorage.getItem('AIMochi');
  const isViewPage = window.location.pathname.includes('/view/');
  const isLiffPage = window.location.pathname.includes('/liff/');
  const isErrorPage = window.location.pathname.includes('/40');
  const [searchParams] = useSearchParams();
  const redirectPath = searchParams.get('redirect_path');

  useEffect(() => {
    if (storage) {
      if (redirectPath) {
        navigate(redirectPath);
      }
      dispatch(fchUser());
    } else if (!isViewPage && !isLiffPage && !isErrorPage) {
      navigate('/user/login');
    }
  }, [isLiffPage, isViewPage, redirectPath, storage, isErrorPage]);

  return (
    // <HelmetProvider>
    <AppProvider>
      <MetaHelmet />
      <JumboApp activeLayout={config.activeLayout}>
        <JumboTheme init={config.theme}>
          <JumboRTL>
            <JumboDialogProvider>
              <JumboDialog />
              <SnackbarProvider maxSnack={3}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <AppLayout>
                    <Suspense
                      fallback={
                        <Div
                          sx={{
                            display: 'flex',
                            minWidth: 0,
                            alignItems: 'center',
                            alignContent: 'center',
                            height: '100%',
                          }}>
                          <CircularProgress sx={{ m: '-40px auto 0' }} />
                        </Div>
                      }>
                      <AppLayoutRoutes />
                    </Suspense>
                  </AppLayout>
                </LocalizationProvider>
              </SnackbarProvider>
            </JumboDialogProvider>
          </JumboRTL>
        </JumboTheme>
      </JumboApp>
    </AppProvider>
    // </HelmetProvider>
  );
}

export default App;
