import queryString from 'query-string';

import * as __ from '../types/search';
import { getRequest, postRequest } from 'app/services';
import axios from 'axios';
import moment from 'moment';

axios.interceptors.response.use(
  response => response,
  error => error
);

export const searchNotes = (uid, keyword, page = 1, start_date, end_date) => {
  const url = `/api/v1/users/${uid}/_search`;

  const body = {
    keyword,
    page,
    start_date,
    end_date,
  };

  return async dispatch => {
    try {
      if (keyword === '') {
        return dispatch(fchNoteList(uid, page, start_date, end_date));
      }
      dispatch({ type: __.SEARCH_NOTES });
      const res = await postRequest(url, body);
      return dispatch(
        searchNotesDone(res.data.notes, res.data.total_count, keyword, page)
      );
    } catch (error) {
      console.log(error);
    }
  };
};

export const searchNotesDone = (notes, total_count, keyword, page) => {
  return {
    type: __.SEARCH_NOTES_DONE,
    payload: { notes, total_count, isSearching: true, keyword, page },
  };
};

export const fchNoteList = (
  uid,
  page = 1,
  start_date,
  end_date,
  page_size = 20
) => {
  const query = {
    page,
    page_size,
  };

  if (start_date && end_date) {
    query.start_date = start_date;
    query.end_date = end_date;
  }

  const url = queryString.stringifyUrl({
    url: `/api/v1/users/${uid}/notes`,
    query,
  });

  return async dispatch => {
    try {
      dispatch({ type: __.FCH_NOTELIST });
      const res = await getRequest(url);
      dispatch(fchNoteListDone(res.data.data, res.data.total_count, page));
    } catch (error) {
      console.log(error);
    }
  };
};

export const fchNoteListDone = (notes, total_count, page) => {
  return {
    type: __.FCH_NOTELIST_DONE,
    payload: { notes, total_count, isSearching: false, keyword: '', page },
  };
};

export const fchNote = (uid, nid, type = 'normal') => {
  const url = `/api/v1/users/${uid}/notes/${nid}`;

  return async dispatch => {
    try {
      dispatch({ type: __.FCH_NOTE });
      const res = await getRequest(url);
      dispatch(fchNoteDone(res.data));
      return res.data;
    } catch (error) {
      console.log(error);
      if (error.response.status === 401 && localStorage.getItem('AIMochi')) {
        window.location.href = '/403';
      }
      if (error.response.status === 404) {
        window.location.href = '/404';
      }
    }
  };
};

export const fchNoteDone = note => {
  return { type: __.FCH_NOTE_DONE, payload: { note } };
};

export const setKeyword = keyword => {
  return { type: __.SET_KEYWORD, payload: { keyword } };
};

export const setSearchDateCondition = (startDate, endDate) => {
  return {
    type: __.SET_SEARCH_DATE_CONDITION,
    payload: { condition: 'date_range', startDate, endDate },
  };
};

export const delSearchDateCondition = (
  uid,
  keyword,
  page,
  start_date,
  end_date
) => {
  return dispatch => {
    dispatch({ type: __.DEL_SEARCH_DATE_CONDITION });
    if (keyword === '') {
      return dispatch(fchNoteList(uid, page, start_date, end_date));
    }
    return dispatch(searchNotes(uid, keyword, page, start_date, end_date));
  };
};

export const fchViewNote = (nId, password = '') => {
  let url = `/public/notes/${nId}`;

  if (password) {
    url += `?password=${password}`;
  }

  return async dispatch => {
    dispatch({ type: __.FCH_VIEW_NOTE });

    try {
      let status;
      const res = await axios.get(url);
      if (res.status === 200) {
        dispatch(fchViewNoteDone(res.data));
        return res;
      }
      if (res.response) {
        status = res.response.status;
      } else {
        status = res;
      }
      return status;
    } catch (error) {
      console.log(error);
    }
  };
};

export const fchViewNoteDone = note => {
  return { type: __.FCH_VIEW_NOTE_DONE, payload: { note } };
};

export const fchNoteTask = (uId, tId) => {
  const url = `/api/v1/users/${uId}/tasks/${tId}`;

  return async dispatch => {
    try {
      dispatch({ type: __.FCH_NOTE_TASK });
      const res = await getRequest(url);
      dispatch(fchNoteTaskDone(res.data));
      return res.data;
    } catch (error) {
      console.log(error);
      if (error.response.status === 401 && localStorage.getItem('AIMochi')) {
        window.location.href = '/403';
      }
    }
  };
};

export const fchNoteTaskDone = note => {
  return { type: __.FCH_NOTE_TASK_DONE, payload: { note } };
};

export const fchStatusList = (
  uid,
  start_date,
  end_date,
  page_size = 20,
  page = 1,
  refresh = false,
) => {
  const query = {
    page,
    page_size,
    start_date: start_date || moment().subtract(7, 'days').format('YYYY-MM-DD'),
    end_date: end_date || moment().format('YYYY-MM-DD'),
    status: '*',
  };

  const url = queryString.stringifyUrl({
    url: `/api/v1/users/${uid}/notes`,
    query,
  });

  return async dispatch => {
    dispatch({ type: __.FCH_STATUSLIST });
    const res = await getRequest(url);
    dispatch(fchStatusListDone(res.data.data, res.data.total_count, page, refresh));
  };
};

export const fchStatusListDone = (notes, status_count, page, refresh) => {
  return {
    type: __.FCH_STATUSLIST_DONE,
    payload: { notes, status_count, page, refresh },
  };
};

export const refreshNoteStatus = (uId, nId) => {
  const url = `/api/v1/users/${uId}/notes/${nId}`;

  return async dispatch => {
    dispatch({ type: __.REFRESH_NOTE_STATUS });
    const res = await getRequest(url);
    dispatch(refreshNoteStatusDone(res.data));
    return res.data;
  };
};

export const refreshNoteStatusDone = note => {
  return { type: __.REFRESH_NOTE_STATUS_DONE, payload: { note } };
};
