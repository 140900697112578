import Immutable from 'immutable';
import {
  DEL_SEARCH_DATE_CONDITION,
  FCH_NOTE_DONE,
  FCH_STATUSLIST_DONE,
  FCH_VIEW_NOTE_DONE,
  REFRESH_NOTE_STATUS_DONE,
  SEARCH_NOTES_DONE,
  SET_KEYWORD,
} from '../types/search';
import {
  DEL_NOTE_DONE,
  DEL_NOTE_LABEL_DONE,
  UPDATE_NOTE_CONTENT_DONE,
  UPDATE_NOTE_LABEL_DONE,
  UPDATE_NOTE_PASSWORD_DONE,
  UPDATE_NOTE_TITLE_DONE,
  UPDATE_NOTE_URL_DONE,
  MERGE_NOTE_DONE,
  DEL_BATCH_NOTE_DONE,
  SUMMARY_NOTE_DONE,
  TRANSLATE_NOTE_DONE,
  CNG_NOTE_VIEW,
  CLEAR_NOTE,
} from '../types/note';
import { FCH_NOTELIST_DONE, SET_SEARCH_DATE_CONDITION } from '../types/search';
import { CREATE_LABEL_DONE, FCH_LABELLIST_DONE } from '../types/label';
import { DEL_NOTE_STATUS_DONE } from '../types/status';

const INIT_STATE = Immutable.OrderedMap({
  results: {
    notes: [],
    note: null,
    labels: [],
    statusList: [],
  },
  total_count: 0,
  status_count: 0,
  config: {
    isLoading: true,
    isSearching: false,
    keyword: '',
    start_date: '',
    end_date: '',
    page: 1,
    searchCondition: [],
    status: {
      type: 'undone',
      start_date: '',
      end_date: '',
      page: 1,
    },
  },
});

const searchReducer = (_ = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case FCH_NOTELIST_DONE:
    case SEARCH_NOTES_DONE:
      const data = Immutable.fromJS(payload.notes)
        .map(note => note.set('view', 'content'))
        .sort((a, b) => b.get('created_at') - a.get('created_at'));
      if (payload.page === 1) {
        return _.setIn(['results', 'notes'], data.toJS())
          .set('total_count', payload.total_count)
          .setIn(['config', 'isSearching'], payload.isSearching);
      }
      return _.setIn(
        ['results', 'notes'],
        [..._.getIn(['results', 'notes']), ...data.toJS()]
      )
        .set('total_count', payload.total_count)
        .setIn(['config', 'isSearching'], payload.isSearching);
    case FCH_NOTE_DONE:
    case FCH_VIEW_NOTE_DONE:
      return _.setIn(['results', 'note'], payload.note);
    case FCH_LABELLIST_DONE:
      return _.setIn(['results', 'labels'], payload);
    case CREATE_LABEL_DONE:
      const labels = _.getIn(['results', 'labels']);
      return _.setIn(['results', 'labels'], [...labels, payload]);
    case UPDATE_NOTE_CONTENT_DONE:
      const _notes = _.getIn(['results', 'notes']);
      const _newNotes = _notes.map(note => {
        if (note.id === payload.nid) {
          const [[key, value]] = Object.entries(payload.doc);
          return { ...note, [key]: value };
        }
        return note;
      });
      const existNote = _.getIn(['results', 'note']);
      if (existNote) {
        const [key, value] = Object.entries(payload.doc)[0];
        const updatedNote = { ...existNote, [key]: value };
        console.log(updatedNote);
        return _.setIn(['results', 'notes'], _newNotes)
                .setIn(['results', 'note'], updatedNote);
      }
      return _.setIn(['results', 'notes'], _newNotes);
    case UPDATE_NOTE_LABEL_DONE:
      const notes = _.getIn(['results', 'notes']);
      const newNotes = notes.map(note => {
        if (note.id === payload.nid) {
          return { ...note, labels: payload.labels };
        }
        return note;
      });
      return _.setIn(['results', 'notes'], newNotes);
    case UPDATE_NOTE_TITLE_DONE:
      const __notes = _.getIn(['results', 'notes']);
      const __newNotes = __notes.map(note => {
        if (note.id === payload.nid) {
          return { ...note, title: payload.title };
        }
        return note;
      });

      return _.setIn(['results', 'notes'], __newNotes);
    case UPDATE_NOTE_URL_DONE:
      const ___notes = _.getIn(['results', 'notes']);
      const ___newNotes = ___notes.map(note => {
        if (note.id === payload.nid) {
          return { ...note, url: payload._url };
        }
        return note;
      });
      return _.setIn(['results', 'notes'], ___newNotes);
    case DEL_NOTE_DONE:
      return _.setIn(
        ['results', 'notes'],
        _.getIn(['results', 'notes']).filter(note => note.id !== payload)
      ).set('total_count', _.get('total_count') - 1);
    case DEL_BATCH_NOTE_DONE:
      const { ids } = payload;
      const __newNoteList = _.getIn(['results', 'notes']).filter(
        note => !ids.includes(note.id)
      );
      return _.setIn(['results', 'notes'], __newNoteList).set(
        'total_count',
        __newNoteList.length
      );
    case SET_KEYWORD:
      return _.setIn(['config', 'keyword'], payload.keyword);
    case SET_SEARCH_DATE_CONDITION:
      return _.setIn(
        ['config', 'searchCondition'],
        _.getIn(['config', 'searchCondition']).concat(payload.condition)
      )
        .setIn(['config', 'start_date'], payload.startDate)
        .setIn(['config', 'end_date'], payload.endDate);
    case DEL_SEARCH_DATE_CONDITION:
      return _.setIn(
        ['config', 'searchCondition'],
        _.getIn(['config', 'searchCondition']).filter(
          condition => condition !== 'date_range'
        )
      )
        .setIn(['config', 'start_date'], '')
        .setIn(['config', 'end_date'], '');
    case DEL_NOTE_LABEL_DONE:
      return _.setIn(
        ['results', 'labels'],
        _.getIn(['results', 'labels']).filter(label => label.id !== payload)
      );
    case UPDATE_NOTE_PASSWORD_DONE:
      return _.setIn(['results', 'note', 'permission'], payload.permission);
    case MERGE_NOTE_DONE:
      const _newNoteList = [..._.getIn(['results', 'notes']), payload.doc].sort(
        (a, b) => b.created_at - a.created_at
      );
      return _.setIn(['results', 'notes'], _newNoteList);
    case FCH_STATUSLIST_DONE:
      if (payload.page === 1) {
        if (payload.refresh) {
          const _statusList = _.getIn(['results', 'statusList']);
          const _newStatusList = Immutable.List(_statusList)
            .map((note, index) => {
              if (index > 5) return note;
              const newNote = payload.notes.find(_note => _note.id === note.id);
              if (newNote && newNote.status !== note.status) {
                return { ...note, status: newNote.status };
              }
              return note;
            })
            .toJS();

          return _.setIn(['results', 'statusList'], _newStatusList);
        }
        return _.setIn(['results', 'statusList'], payload.notes)
          .set('status_count', payload.status_count)
          .setIn(['config', 'status', 'page'], payload.page);
      }
      return _.setIn(
        ['results', 'statusList'],
        [..._.getIn(['results', 'statusList']), ...payload.notes]
      )
        .set('status_count', payload.status_count)
        .setIn(['config', 'status', 'page'], payload.page);
    case REFRESH_NOTE_STATUS_DONE:
      const newStatusList = _.getIn(['results', 'statusList']).map(status => {
        if (status.id === payload.note.id) {
          return payload.note;
        }
        return status;
      });
      if (payload.note.status === 'DONE') {
        const _notesList = _.getIn(['results', 'notes']);
        const index = _notesList.findIndex(note => note.id === payload.note.id);
        if (index === -1) {
          const _newNoteList = Immutable.List(_notesList).unshift(payload.note);
          return _.setIn(['results', 'notes'], _newNoteList).setIn(
            ['results', 'statusList'],
            newStatusList
          );
        }
      }
      return _.setIn(['results', 'statusList'], newStatusList);
    case SUMMARY_NOTE_DONE:
      const _notesList = _.getIn(['results', 'notes']);
      const ___newNoteList = Immutable.List(_notesList)
        .unshift(payload.note)
        .toJS();
      return _.setIn(['results', 'notes'], ___newNoteList);
    case TRANSLATE_NOTE_DONE:
      const _existNote = _.getIn(['results', 'note']);
      console.log('payload', payload);
      if (_existNote) {
        return _.setIn(['results', 'note', 'translate'], payload.note.content);
      }
      return _;
    case DEL_NOTE_STATUS_DONE:
      const _statusList = _.getIn(['results', 'statusList']);
      const _newStatusList = _statusList.filter(
        status => status.id !== payload.nId
      );
      return _.setIn(['results', 'statusList'], _newStatusList);
    case CNG_NOTE_VIEW: {
      const { nId, view } = payload;
      const _noteList = _.getIn(['results', 'notes']);
      const _newNoteList = _noteList.map(note => {
        if (note.id === nId) {
          return { ...note, view };
        }
        return note;
      });
      return _.setIn(['results', 'notes'], _newNoteList);
    }
    
    case CLEAR_NOTE: {
      return _.setIn(['results', 'note'], null);
    }
    default:
      return _;
  }
};

export default searchReducer;
